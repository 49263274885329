import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import Login from "./pages/Login.js";
import "./components/common/auth.js";
import Courses from "./pages/Courses.js";
import Overview from "./pages/Overview.js";
import NavBar from "./components/common/NavBar.js";
import OpenPlannerAPI from "./components/common/OpenPlannerAPI.js";
import Container from "./components/common/Container.js";
import Course from "./pages/Course.js";
import Calendar from "./pages/Calendar.js";
import Settings from "./pages/Settings.js";
import Contact from "./pages/Contact.js";
import { isLoggedIn } from "./components/common/auth.js";
import Home from "./pages/Home.js";
import FeedbackPopup from "./components/common/FeedbackPopup.js";

const theme = createTheme({
  palette: {
    primary: {
      main: "#670083",
    },
    secondary: {
      main: "#004D99",
    },
  },
});

function App() {
  const [globalBox, setGlobalBox] = React.useState(<></>);
  // Redirect if using mobile using detectmobilebrowser.com's helper
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);

  useEffect(() => {
    const feedbackShown = localStorage.getItem("feedbackShown");
    if (!feedbackShown) {
      setIsFeedbackOpen(true);
    }
  }, []);

  const handleCloseFeedback = () => {
    localStorage.setItem("feedbackShown", "true");
    setIsFeedbackOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <div id="globalBox">{globalBox}</div>
      <div>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route
              path="/settings"
              element={
                <Container>
                  <Settings />
                </Container>
              }
            />
            <Route
              path="/Overview"
              element={
                <Container>
                  <FeedbackPopup
                    open={isFeedbackOpen}
                    onClose={handleCloseFeedback}
                  />
                  <Overview />
                </Container>
              }
            />
            <Route
              path="/Courses"
              element={
                <Container>
                  <Courses setGlobalBox={setGlobalBox} />
                </Container>
              }
            />
            <Route
              path="/Course/:id"
              element={
                <Container>
                  <Course />
                </Container>
              }
            />
            <Route
              path="/Calendar"
              element={
                <Container>
                  <Calendar />
                </Container>
              }
            />
            <Route
              path="/Contact"
              element={
                <Container>
                  <Contact />
                </Container>
              }
            />
          </Routes>
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
